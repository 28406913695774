import { graphql } from 'gatsby';
import Template from '../template';

export default Template;

export const pageQuery = graphql`
  query($id: String!, $skip: Int!, $limit: Int!) {
    page: strapiListingPage(id: { eq: $id }) {
      ...StrapiListingPageFragment
    }
    articles: allStrapiBlogArticle(
      skip: $skip,
      limit: $limit,
      sort: {order: DESC, fields: articleSettings___publishDate}
      filter: {articleSettings: {publishStatus: {eq: true}}}
    ) {
      edges {
        node {
          ...ArticleFragment
        }
      }
    }
    recentSidebarArticles: allStrapiNewsArticle(
      limit: 10,
      sort: {
        order: DESC,
        fields: articleSettings___publishDate
      },
      filter: {
        articleSettings: {publishStatus: {eq: true}}
      }) {
      edges {
        node {
          ...ArticleFragment
        }
      }
    }
    labelsAndMessages {
      en
    }
  }
`;
